<template>
    <section class="forms">
        <div class="page-header">
            <h3 class="page-title">
                Edit Video of {{ this.category_name }}
            </h3>

            <div class="button-group">
                <button type="button" class="btn btn-info" @click="goBack()">
                    <i class="mdi mdi-keyboard-backspace"></i> Back to Videos Listing</button>

                <!-- <b-dropdown class="" right variant="success">
                    <template slot="button-content">
                        Add Content
                    </template>
                    <b-dropdown-item @click="showAddModal('audio')">Audio</b-dropdown-item>
                    <b-dropdown-item @click="showAddModal('video')">Video</b-dropdown-item>
                </b-dropdown> -->
            </div>



            <!-- <router-link class="btn btn-primary believe-btn" :to="`/hypnosis/list/${current_category_id}`">
            <i class="mdi mdi-keyboard-backspace"></i> Back</router-link> -->
            <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">hypn</a></li>
          <li class="breadcrumb-item active" aria-current="page">Basic</li>
        </ol>
      </nav> -->
        </div>
        <div class="row">
            <div class="col-md-8 m-auto d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <!-- <h4 class="card-title">Default form</h4>
                                <p class="card-description">
                                    Basic form layout
                                </p> -->
                                <b-form @submit.stop.prevent="onSubmit">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Title"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="name" aria-describedby="input-1-live-feedback">
                                                </b-form-input>
                                                <p class="err" v-if="name_err != ''">{{ name_err }}</p>
                                            </b-form-group>


                                            <b-form-group id="example-input-group-1" label="Artist"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1" v-model="artist"
                                                    aria-describedby="input-1-live-feedback">
                                                </b-form-input>
                                                <p class="err" v-if="artist_err != ''">{{ artist_err }}</p>
                                            </b-form-group>
                                        </div>
                                    </div>


                                    

                    
                                    <div class="container-fluid my-4" v-if="form_type == 'video'">
                                        <!-- for video  -->


                                        <div class="file-box row" style="padding-bottom: 30px !important">
                                            <div class="col-md-12">
                                                <div class="row file-text-box" style="margin-left:0px !important;">
                                                    <div class="col-md-6">
                                                        <uploadVideos :fileType="['mp4']" @success="uploadSuccess"
                                                            file_type_url="lesson-content/videos" />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <VideoCompo v-if="this.video_file_path_cloud" :key="video_key"
                                                            :videopath="this.video_file_path_cloud" />

                                                        <VideoCompo v-if="this.video_file_path_editpage" :key="video_key"
                                                            :videopath="this.video_file_path_editpage" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="err" v-if="audioFile_err != ''">{{ audioFile_err }}
                                        </p>

                                    </div>


                                    <div class="container-fluid my-4">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="file-box row" @click="$refs.image.click()">
                                                    <div class="col-md-1">
                                                        <i class="mdi mdi-cloud-upload" style="font-size:50px"></i>
                                                        <input class="ml" type="file" 
                                                            @change="onAudioImageChange" ref="image"
                                                            style="display: none" />
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="file-text-box">
                                                            <span>Upload</span><br />
                                                            <small>Upload thumbnail image here</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img v-if="image_url !== ''" :src="image_url"
                                                            class="img-thumbnail rounded-circle" alt="Image"
                                                            style="height: 65px; width: 65px; margin-top:8px" />
                                                    </div>
                                                </div>
                                                <p class="err" v-if="image_error != ''">{{ image_error }}</p>
                                            </div>
                                        </div>
                                    </div>



                                    <Tag @updateTag="updateTagFromChild" oldtags="" />
                                    <p class="err" v-if="tags_err != ''">{{ tags_err }}</p>

                                    <b-form-group id="example-input-group-1" label="Description"
                                        v-if="this.form_type != 'text'" label-for="example-input-1">
                                        <b-form-textarea @keydown.native="test_keydown_handler"
                                         id="descriptionTextarea" name="example-input-1"
                                            v-model="description" aria-describedby="input-1-live-feedback"
                                            placeholder="Enter something..." rows="3" max-rows="6"></b-form-textarea>
                                        <p class="err" v-if="desc_err != ''">{{ desc_err }}</p>
                                    </b-form-group>

                                    <!-- :disabled='is_loading' -->
                                    <!-- v-on:click="submitFunctions" -->


                                    <!-- :disabled="!ready_to_submit" -->
                                    <b-button v-if="form_type == 'video'" type="submit" variant="success"
                                        class="btn-block believe-btn">
                                        <i
                                            :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                        Submit
                                    </b-button>

                                    <b-button v-else type="submit" variant="success" class="btn-block believe-btn">
                                        <i
                                            :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                        Submit
                                    </b-button>
                                </b-form>
                                <vue-snotify></vue-snotify>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
import Tag from "@/components/Tag.vue";
import AudioFile from "@/components/AudioFile.vue";
import uploadVideos from "@/components/UploadVideos.vue";
import VideoCompo from "./VideoCompo.vue";
import { VueEditor } from "vue2-editor";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioAddModal',
    mixins: [validationMixin],
    data() {
        return {
            category_name : null,
            class_val: "col-md-6",
            videoFile_err: "",
            video_file_path_editpage : null,
            video_file_path: null,
            video_file_path_cloud : null,
            video_thumbnail: null,
            ready_to_submit: false,
            video_key: 0,
            form_type: null,
            data_length: 0,
            selected_module: null,
            all_modules: [],
            module_error: "",
            count: 0,
            msg: "",
            is_loading: false,

            pdf_file: "",
            pdfFile_err: "",

            artist_err: "",
            author_err: "",

            name_err: "",
            desc_err: "",

            file_duration_err: "",

            audioFile: "",
            audioFile_err: "",
            tags_err: "",
            file_duration: "",
            tags: [],
            id : "",
            name: "",
            category_id : "",
                description: "",
                cat_id: "",
                duration: "",
                artist: "",
                author: "",

                image_error: "",
            image_url: "",
                image: "",
        }
    },

    components: {
        Tag,
        AudioFile,
        uploadVideos,
        VideoCompo,
        VueEditor
        // simpleSnotifysuccess
    },
    validations: {
        form: {
            // name: {
            //     required,
            // },
            // description: {
            //     required,
            // },
            // artist: {
            //     required,
            // },
        }
    },
    watch: {
        name(newVal){
            // if (newVal != "") {
            //     this.name_err = "";
            // }
            if (newVal != "" && newVal.length <= 100) {
                this.name_err = "";
            }
        },
        description(newVal) {
            if (newVal != "") {
                this.desc_err = "";
            }
        },
        // artist(newVal) {
        //     if (newVal != "") {
        //         this.artist_err = "";
        //     }
        // },
        author(newVal) {
            if (newVal != "") {
                this.author_err = "";
            }
        },
        file_duration(newVal) {
            if (newVal != "") {
                this.file_duration_err = "";
            }
        },
        selected_module(newVal) {
            if (newVal != "") {
                this.module_error = "";
            }
        },
        tags(newVal) {
            if (newVal.length > 0) {
                this.tags_err = "";
            }
        },
        artist(newVal) {
            if (newVal != "" && newVal.length <= 30) {
                this.artist_err = "";
            }
        },
    },

    mounted() {
        window.addEventListener("keypress", function (e) {
            if (e.code == "Enter") {
                e.preventDefault()
            }
        }.bind(this));
        // this.FetchAllModules();
        this.items();
    },

    methods: {


        onAudioImageChange(e) {
            this.image = "";
            this.image_url = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, 'image1')
            if (status) {
                this.image_error = "";
                this.image = file;
                this.image_url = URL.createObjectURL(file)
            }
        },
    
        
        checkFileType(file, num) {
            console.log(file,num);
            var types = ['image/jpeg', 'image/png', 'image/jpg']
            var msg = "The image must be a file of type: jpeg, jpg, png";
                if (!types.includes(file.type)) {
                    if (num == 'image1') {
                        this.image_error = msg
                        this.image = "";
                        this.image_url = "";
                    }
                    return false;
                }
                return true;
        },

        test_keydown_handler(event) {
            if (event.which === 13) {
                let txt = document.getElementById('descriptionTextarea');
                let indexOfChar = txt.value.slice(0, txt.selectionStart).length + 1
                txt.value = txt.value.slice(0, txt.selectionStart) + '\r\n' +
                txt.value.slice(txt.selectionStart, txt.value.length);
                this.setCaretPosition('descriptionTextarea',indexOfChar);
            }
        },

        setCaretPosition(elemId, caretPos) {
            var elem = document.getElementById(elemId);
            if (elem != null) {
                if (elem.createTextRange) {
                    var range = elem.createTextRange();
                    range.move('character', caretPos);
                    range.select();
                }
                else {
                    if (elem.selectionStart) {
                        elem.focus();
                        elem.setSelectionRange(caretPos, caretPos);
                    }
                    else
                        elem.focus();
                }
            }
        },
        onSubmit() {
            this.$v.form.$touch()
            if (this.$v.form.$anyError) {
                return false;
            }

            if (this.form_type == "video") {
                this.addVideo();
            }
        },

        showAddModal(type) {
            this.form_type = type;
            if (type == 'audio') {
                this.video_file_path = null,
                    this.audioFile_err = "",
                    this.video_thumbnail = null,
                    this.ready_to_submit = false,
                    this.class_val = 'col-md-4'
            }
            else if (type == 'video') {
                this.audioFile_err = "",
                    this.audioFile = "",
                    this.class_val = 'col-md-6'
            }
        },

        chk_module() {    console.log('errors:::::::');
                        console.log(this.name_err);
                        console.log(this.name);
                        console.log(this.desc_err);
                        console.log(this.description);
                        console.log(this.author_err);
                        console.log(this.author);
            if (this.selected_module != "") {
                this.module_error == "";
            }
        },

        uploadSuccess(data) {
            this.file_duration_db = data?.duration ?? "0";
            this.video_file_path = data.path ?? null;
            this.video_file_path_cloud = data.path2 ?? null;
            this.video_thumbnail = data.thumbnail ?? null;
            this.video_key++;
            this.audioFile_err = "";
            // console.log('success');
            // console.log(this.video_file_path);
            this.ready_to_submit = true;
            this.video_file_path_editpage = null
            console.log(this.video_file_path);
            console.log(this.video_thumbnail);
        },
        async items() {
            let data = await this.$store.getters['getEditFormData'];

            if (data.length == 0) {
                data = localStorage.getItem('data_for_edit');
                data = JSON.parse(data);
            }

            this.category_name = data?.category_name

            this.name = data?.name;
            this.artist = data?.artist;
            this.description = data?.description;
            this.url = data?.url;
            this.file_duration = data?.duration;
            this.image_url = data?.image;
            // this.image = data?.image;
            this.tags = data?.tag;
            this.id = data?.id;
            this.video_file_path_editpage = data?.path;
        
            this.data_length = data?.data_length;
            this.form_type = data?.form_type


            this.category_id = data?.category_id;
    
            if (data?.form_type == 'audio') {
                this.class_val = 'col-md-4'
            }
            else {
                this.class_val = 'col-md-6'
            }
        },

        // async FetchAllModules() {
        //     try {
        //         let result = await api.get(`/admin/all-lessons`);
        //         console.log("fetch all courses 1010101");
        //         console.log(result);
        //         // console.log(result.data.all_course_categories);
        //         this.all_modules = result.data.all_lessons;
        //         // var obj = {"id" : "all","title" : "all"}
        //         // this.all_categories.unshift(obj);
        //     } catch (error) {
        //         this.error = error.response.data.message;
        //         this.$toast.error(this.error);
        //     }
        // },

        closeAudioPlayer() {
            this.audioFile = "";
            this.$refs.audioFile.value = null;
        },

        goBack() {
            let prev_url = `/video/list/${this.category_id}`;
            this.$router.push({ path: prev_url });
        },

        updateTagFromChild(arr) {
            this.tags = arr;
        },




     

       


        async addVideo() {
            if (this.name.length > 100 || this.artist.length > 30) {
                if (this.name.length > 100) {
                    this.name_err = "Maximum limit 100 characters";
                }
                if (this.artist.length > 30) {
                    this.artist_err = "Maximum limit 30 characters";
                }
                return false;
            }

            // console.log("video path ::: " , this.video_file_path);
            // console.log("video edit form path ::: " , this.video_file_path_editpage);
            if (this.tags.length != 0
                && this.name != "" &&
                this.artist != "" &&
                this.description != ""
            ) {
                try {
                    this.is_loading = true;
                    let obj = this.$store.getters['getEditFormData'];
                    let endpoint = "/admin/update-video";
                    // let payload = {
                    //     id : this.id,
                    //     name: this.name,
                    //     cat_id: this.category_id,
                    //     description: this.description,
                    //     type: obj.form_type,
                    //     artist: this.artist,
                    //     duration: this.file_duration_db,
                    //     order: this.data_length + 1,
                    //     tag: this.tags,
                    // };


                    const formData = new FormData();
                    formData.append('id', this.id);
                    formData.append('name', this.name);
                    formData.append('cat_id', this.category_id);
                    formData.append('description', this.description);
                    formData.append('duration', this.file_duration_db);
                    formData.append('order', this.data_length + 1);
                    formData.append('tag', this.tags);
                    formData.append('type', obj.form_type);
                    formData.append('artist', this.artist);

                    if (this.image != "" && this.image != null) {
                        formData.append("image", this.image);
                    }


                    if (this.video_file_path_editpage == null) {
                        // console.log('from if');
                        // console.log(this.video_file_path);

                        formData.append('video_thumbnail', this.video_thumbnail);
                        formData.append('video_file_path', this.video_file_path);

                        // payload['video_thumbnail'] = this.video_thumbnail;
                        // payload['video_file_path'] = this.video_file_path;
                    }
                    else{
                        formData.append('video_thumbnail', null);
                        formData.append('video_file_path', null);
                    }

                    // console.log("payload ::::: " , payload);

                    let result = await api.post(endpoint, formData);
                    const msg = await result.data.message;
                    console.log('after requrest');
                    console.log(result);
                    this.$store.dispatch('setMessage', { msg });
                    let id2 = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
                    await this.$store.dispatch(`video/all`, {
                        id: id2
                    });
                    let prev_url = `/video/list/${id2}`;
                    this.$router.push({ path: prev_url });
                    this.is_loading = false;
                } catch (error) {
                    console.log(error);
                }
            }
            else {
                this.name_err = this.name == "" && this.name_err == ""
                    ? "Title is required" : this.name_err;
                this.desc_err = this.description == "" && this.desc_err == ""
                    ? "Description is required" : this.desc_err;

                this.artist_err = this.artist == "" && this.artist_err == ""
                    ? "Artist is required" : this.artist_err;
                this.tags_err = (this.tags.length == 0 && this.tags_err == '') ? "Tags required" : this.tags_err
            }
        },




        showSnotifySuccess(msg) {
            let obj = this.$store.getters['getMessage'];
            this.$snotify.success(obj.msg, {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },
        showSnotifyError(msg) {
            let obj = this.$store.getters['getMessage'];
            this.$snotify.error(obj.msg, {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },

    },




}
</script>


<style>
.ql-editor {
    background-color: #909090;
    max-height: 100px;
}
</style>